<template>
  <div class="registration ft-register-user-view" id="registration">
  <div class="ft-register-user-view__header-title">
    Register For CI Audit Application
    </div>
    <div class="ft-register-user-view__form-container">
      <h1></h1>
        <mds-form size="medium" class="ft-register-user-view__form" @submit.native.prevent="onRegister">
            <mds-input required v-model="firstName" size="medium" label="First Name"
                class="ft-register-user-view__form-inputs" id="ft-register-user-view__form-input-first-name"
                maxLength="50"></mds-input>
            <mds-input required v-model="lastName" size="medium" label="Last Name"
                class="ft-register-user-view__form-inputs" id="ft-register-user-view__form-input-last-name"
                maxLength="50"></mds-input>
            <mds-input required v-model="email" size="medium" label="Email Address"
                class="ft-register-user-view__form-inputs" maxLength="50" id="ft-register-user-view__form-input-email"
                :error="v$.email.$error || invalidEmail"
                :error-text="emailErrorMessage"
               ></mds-input>
            <mds-button-container class="ft-register-user-view__form-actions">
                <mds-button variation="primary" size="large" type="submit" id="ft-register-user-view__form-submit"
                    > Register</mds-button>
            </mds-button-container>
            <p>Already have an account?
                <mds-button
                  variation="secondary"
                  type="button"
                  class="login-btn"
                  :href="redirectToLogin()"
                  text="Login Here"
                ></mds-button></p>
        </mds-form>
      </div>
    </div>
</template>
      
<script>
import MdsInput from '@mds/input-vue3';
import { MdsButton, MdsButtonContainer } from '@mds/button-vue3';
import MdsForm from '@mds/form-vue3';
import useValidate from '@/composables/useValidate';
import { required, maxLength } from '@vuelidate/validators';
import { ref } from 'vue';
import { registerUser } from '../apis/ci-audit.js';
export default {
    name: 'RegisterUser',
    components:{
        MdsInput,
        MdsButton,
        MdsButtonContainer,
        MdsForm,
    },
    setup() {
      const firstName = ref('');
      const lastName = ref('');
      const email = ref('');
      // const password = ref('');
      // const confirmPassword = ref('');
    //   const termsConditionsInput = ref(false);

    //   const validEmail = (value) => validateEmail(value);
      const rules = {
        firstName: { required, maxLength: maxLength(50) },
        lastName: { required, maxLength: maxLength(50) },
        email: { required, maxLength: maxLength(50) },
      };
      const { v$ } = useValidate(rules, {
        firstName, lastName, email
      });
      return {
        v$,
        firstName,
        lastName,
        email,
      };
    },
    computed: {
    emailErrorMessage() {
      let errorMessage = '';
      switch (this.emailValidateStatus) {
        case 'existing':
          errorMessage = 'User with this email already exist.';
          break;
        case 'publicDomain':
          errorMessage = [this.$t('registerUserForm.emailErrors.validEmailPublic')];
          break;
        case 'competitorDomain':
        errorMessage = [this.$t('registerUserForm.emailErrors.validEmailCompetitor')];
          break;
        default:
          errorMessage = this.v$.email.$error ? [
            this.$t(`registerUserForm.emailErrors.${this.v$.email.$errors[0].$validator}`),
          ] : [''];
          break;
      }
      return errorMessage;
    },
  },
  watch: {
    email() {
      if (this.invalidEmail) {
        this.invalidEmail = false;
        this.emailValidateStatus = '';
      }
    },
  },
  props:['showLoaderToggle', 'showSuccessAlert'],
    data() {
        return {
          showSuccessMsg: false,
          alertData: {
            variation: 'success',
            title: "",
          },
          emailValidateStatus: '',
          invalidEmail: false,
        }
    },
    methods: {
      onRegister() {
        this.showLoaderToggle(true);
        const data = {
          email: this.email
        };
        registerUser(data)
        .then((resp) => {
          // console.log(resp.status)
          switch (resp?.status) {
              case 200:
                // this.alertData.title = "Your account has been successfully created, please check your inbox for details.";
                // this.showSuccessMsg = true;
                this.showSuccessAlert("Your account has been successfully created, please check your inbox for details.");
                break;
              default:
                break;
          }
        })
        .catch((err)=>{
          // console.log("err",err.response)
          // this.alertData.title = err.response.data.message
          // this.alertData.variation = "error"
          // this.showSuccessMsg = true;
          switch (err.response?.status) {
              case 201:
                // this.alertData.title = "Your account has been successfully created, please check your inbox for details.";
                // this.showSuccessMsg = true;
                this.showSuccessAlert("Your account has been successfully created, please check your inbox for details.");
                break;
              case 409:
                this.invalidEmail = true;
                this.emailValidateStatus = 'existing';
                break;
              case 422:
                if (err.response?.invalid_email && err.response?.invalid_email_domain) {
                  this.invalidEmail = true;
                  this.emailValidateStatus = 'publicDomain';
                } else if (err.response?.invalid_email) {
                  this.invalidEmail = true;
                  this.emailValidateStatus = 'competitorDomain';
                } else {
                  // this.callAlertEvent('Error', 'It looks like something went wrong');
                }
                break;
              default:
                break;
          }
        })
        .finally(() => {
          this.showLoaderToggle(false);
        })
      },
      redirectToLogin(){
        const loginUrl = new URL(process.env.VUE_APP_LOGIN_URL);
        loginUrl.searchParams.append("targetUrl", process.env.VUE_APP_CR_URL+"/home");
        return loginUrl.href;
      },
}
}
</script>
      
<style lang="scss" module>
.mds-form--medium-layout .mds-field-error{
  display: inline;
}
.ft-register-user-view {
  &__header-title {
    text-align: center;
    // padding-top: $mds-space-2-and-a-half-x;
    // padding-bottom: $mds-space-5-x;
    // @include mds-level-4-heading();
    font-style: normal;
    font-size: 23px;
    font-weight: 300;
    line-height: 27px;
    padding: 10px;
  }
  &__form {
    margin: auto;
    &-container {
      display: flex;
      margin: auto;
    }
    &-terms-link {
    //   color: $mds-interactive-color-primary-default;
    }
    &-actions {
    //   padding-top: $mds-space-3-x;
      float: right;
    }
  }
  &__form-inputs {
    width: 400px;
  }
  margin-top: 20px;
  .login-btn{
    border: none !important;
    padding: 0px !important;
  }
  .login-btn:hover, .login-btn:focus, a.login-btn:hover, a.login-btn:focus{
    background-color: #fff;
    box-shadow: none !important;
    color: #5e5e5e;
  }
}</style>